import FileInfoGrid, { IFileInfo } from '../../../components/file-displaying/file-info-grid';

import Box from '@mui/material/Box';
import Button from 'devextreme-react/button';
import DragDropUploaderPopup from '../../../components/file-management/drag-drop-uploader-popup';
import Grid from '@mui/material/Grid';
import { IFile } from '../../../components/auto-form';
import ImageGrid from '../../../components/file-displaying/image-grid';
import MuiButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useBreakpoints from '../../../shared/hooks/use-breakpoints';
import { useState } from 'react';

export interface FormFileProps {
    files: IFileInfo[];
    refetch: VoidFunction;
    uploadUrl?: string;
    buttons?: string[];
    showTitle?: boolean;
}

interface FormFileUploaderProps {
    uploadUrl: string;
    refetch: () => void;
}

export const FormFilesUploader = ({ uploadUrl, refetch }: FormFileUploaderProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const overrideCloseFunction = () => {
        setIsVisible(false);
    };
    return (
        <>
            <Button
                style={{ marginTop: '-55px', float: 'right' }}
                icon='add'
                onClick={() => setIsVisible(true)}
            ></Button>
            <DragDropUploaderPopup
                uploadUrl={uploadUrl}
                popupOptions={{
                    visible: isVisible,
                    overrideCloseFunction: overrideCloseFunction,
                    hideOnOutsideClick: true,
                    height: '50%',
                    onSave: refetch,
                }}
                uploaderOptions={{
                    multiple: true,
                    // allowedFileExtensions: ['.pdf']
                    // accept: 'pdf'
                }}
            />
        </>
    );
};

export const FormFiles = ({ files, refetch, uploadUrl, buttons, showTitle }: FormFileProps) => {
    return (
        <Box className={showTitle ? 'changeBackground' : ''}>
            <Typography
                pt='15px'
                pb='10px'
                mb='10px'
                fontFamily={'"Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif'}
                fontSize={'19px'}
                textAlign='left'
                visibility={showTitle ? 'visible' : 'hidden'}
            >
                Filer og Billeder
            </Typography>
            {uploadUrl && <FormFilesUploader uploadUrl={uploadUrl} refetch={refetch} />}
            <ImageGrid files={files?.filter((file) => (file as IFile).type === 'image') ?? []} />
            <FileInfoGrid
                getUrl=''
                buttons={buttons}
                data={files?.filter((file) => file.type === 'file') ?? []}
                onDelete={refetch}
            />
        </Box>
    );
};

interface IFormButtons {
    handleButClick: (but?: string) => void;
}

export const FormButtons = ({ handleButClick }: IFormButtons) => {
    const { sm } = useBreakpoints();
    return (
        <Grid
            container
            spacing={2}
            sx={{ pr: '20px', pl: '20px', marginLeft: sm ? '-16px' : '56px' }}
            width={sm ? '100%' : 'calc(100% - 56px)'}
        >
            <Grid item xs={true} key='save'>
                <MuiButton
                    type='submit'
                    name='save'
                    value='save'
                    variant='contained'
                    color='success'
                    sx={(theme) => ({
                        transition: theme.transitions.create('all', {
                            easing: theme.transitions.easing.easeInOut,
                            duration: '1s',
                        }),
                        width: '100%',
                        minWidth: '68px',
                        maxWidth: '250px',
                        pointerEvents: 'all',
                    })}
                    onClick={() => handleButClick()}
                >
                    Gem
                </MuiButton>
            </Grid>
        </Grid>
    );
};
