import 'devextreme-react/select-box';
import 'devextreme-react/date-box';

import AutoGridEgenskaber, { IEgenskaberRef } from './egenskaber';
import { AutoGridFileGrid, AutoGridImageGrid } from './filelist';
import Form, { Item } from 'devextreme-react/form';
import { IFile, IItem } from '../auto-form/v1';
import { RefObject, useContext, useRef } from 'react';

import Api from '../../shared/networking/api';
import { AutoGridContext } from './context';
import Box from '@mui/material/Box';
import Button from 'devextreme-react/button';
import { CenterCenterBox } from '../mui/styled-mui';
import Divider from '@mui/material/Divider';
import { ICommandProps } from './render-commands';
import { IDictionary } from '../../shared/utils/types';
import { Scrollable } from '../misc/flex';
import { TItemValue } from '../auto-form/v1/interfaces-and-defaults';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { isMobile } from 'react-device-detect';
import { sortObjectArray_Number } from '../../shared/utils/sorts';
import useColumnSlice from '../../shared/hooks/use-column-slice';
import { useSnackbar } from 'notistack';
import { FieldDataChangedEvent } from 'devextreme/ui/form';

interface IPopupForm {
    props: ICommandProps;
    hide: VoidFunction;
}

/** This interface is mocked from what was used below, to remove 'any' */
export interface InputEvent {
    value: string;
    event: {
        currentTarget: {
            value: number;
        };
    };
    component: {
        _parsedValue: number;
    };
}

export interface InputStrategy {
    (e: InputEvent, id: string, data: any, changes: IDictionary, formRef: RefObject<Form>): void;
}

interface PopupFormProps {
    data: any;
    hide: VoidFunction;
    layout: any;
    url: string;
    afterSave?: (changes: IDictionary) => any;
    append?: {
        get?: string;
        post?: string;
        put?: string;
        remove?: string;
    };
    onInput?: InputStrategy;
}

const PopupForm = ({ data, hide, layout, url, afterSave, append, onInput }: PopupFormProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const changes: IDictionary = {};
    const slicedColumns = useColumnSlice(
        [...layout.columns].sort(sortObjectArray_Number('formIndex')).filter((c) => c.visibleInForm !== false)
    );
    const formRef = useRef<Form>(null);
    const egenskaberRef = useRef<IEgenskaberRef>(null);
    const egeId = useRef<string>(data.id ?? '');
    const filelist = useRef<IFile[]>(data.files ?? data.filer ?? []);

    const inputHandler = async (e: InputEvent, id: string) => {
        onInput?.(e, id, data, changes, formRef);
    };

    const onFieldDataChanged = (e: FieldDataChangedEvent) => {
        if (e.dataField) {
            changes[e.dataField] = e.value;
        }
    };

    const saveEditData = async () => {
        const formData = new FormData();
        formData.append('key', data.id);
        formData.append('values', JSON.stringify({ ...changes }));

        const response = await Api.put(`${url + (append?.put ?? '')}`, formData);
        if (!Api.ok(response)) enqueueSnackbar('Undskyld. Noget gik galt.', { variant: 'error' });
        else enqueueSnackbar('Gemt', { variant: 'success' });
        afterSave?.(changes);
    };

    const submitEgenskaber = async () => {
        const data = new FormData(egenskaberRef.current?.egenskaberRef.current?.element);
        const items: IItem[] = [];
        const prevData = egenskaberRef.current?.data ?? [];
        prevData.map((gruppe) => gruppe.items.map((item) => items.push(item)));
        const keys = items.map((item) => item.id);

        for (const dataKey of keys) {
            for (const [key, value] of data.entries()) {
                if (dataKey === key) {
                    if (items.find((item) => item.id === dataKey && (item.value ?? '') === value)) {
                        data.delete(key);
                    } else {
                        for (let i = 0; i < prevData.length; i++) {
                            for (let k = 0; k < prevData[i].items.length; k++) {
                                if (prevData[i].items[k].id === key) {
                                    prevData[i].items[k].value = value as TItemValue;
                                }
                            }
                        }
                    }
                }
            }
        }

        let length = 0;
        data.forEach((_value, _key) => {
            length += 1;
        });

        if (length > 0) {
            const response = await Api.post(egenskaberRef.current?.url + (egeId.current ?? ''), data);
            if (Api.ok(response)) {
                egenskaberRef.current?.refetch();
            }
        }
    };

    return (
        <div style={{ height: '100%' }}>
            <CenterCenterBox sx={{ p: 0, height: '34px !important' }}>
                <Button
                    type='success'
                    onClick={() => {
                        submitEgenskaber();
                        saveEditData();
                        hide();
                    }}
                    style={{ height: '100%', width: '67.5px' }}
                >
                    Gem
                </Button>
                <Box width={'10px'} />
                <Button
                    onClick={() => {
                        hide();
                    }}
                    style={{ height: '100%', width: '89px' }}
                >
                    Annuller
                </Button>
            </CenterCenterBox>
            <Divider sx={{ pt: '5px' }} />
            <div style={{ justifyContent: 'center', display: 'flex', height: 'calc(100% - 34px)' }}>
                <Scrollable style={{ height: '100%', maxWidth: '1000px', width: 'calc(100% - 40px' }}>
                    <Form
                        labelLocation='top'
                        formData={data}
                        onFieldDataChanged={onFieldDataChanged}
                        ref={formRef}
                        style={{ marginRight: '10px' }}
                    >
                        {/* Form Items */}
                        <Item colSpan={2}>
                            <span style={{ fontSize: '19px' }}>Redigerer</span>
                            <Divider sx={{ pb: '5px', mb: '5px' }} />
                        </Item>
                        {layout.columns &&
                            slicedColumns.map((arr, i) => {
                                return (
                                    <Item itemType='group' colCount={12} colSpan={2} key={`fig1-group-${i}`}>
                                        {/* Form Columns */}
                                        {arr.map((column) => {
                                            if (column.dataType === 'date')
                                                return (
                                                    <Item
                                                        colSpan={column.colSpan === 'max' ? 12 : column.colSpan}
                                                        label={{
                                                            visible: !column.hideCaption,
                                                        }}
                                                        dataField={column.dataField}
                                                        name={column.dataField}
                                                        key={`${column.dataField}`}
                                                        editorType='dxDateBox'
                                                        editorOptions={{
                                                            displayFormat: 'dd-MM-yyyy',
                                                            forceIsoDateParsing: true,
                                                            dateSerializationFormat: 'yyy-MM-dd',
                                                            pickerType: isMobile ? 'native' : 'calendar',
                                                            acceptCustomValue: true,
                                                            openOnFieldClick: false,
                                                            onValueChanged: (e: InputEvent) =>
                                                                inputHandler(e, column.dataField),
                                                            readOnly: !column.allowEditing,
                                                        }}
                                                    />
                                                );
                                            // Default
                                            return (
                                                <Item
                                                    colSpan={column.colSpan === 'max' ? 12 : column.colSpan}
                                                    label={{
                                                        visible: !column.hideCaption,
                                                        text: column.caption,
                                                    }}
                                                    dataField={column.dataField}
                                                    name={column.dataField}
                                                    key={`${column.dataField}`}
                                                    editorType={column.lookup ? 'dxSelectBox' : column.editorType}
                                                    editorOptions={{
                                                        maxLength: column.maxLength,
                                                        onInput: (e: InputEvent) => inputHandler(e, column.dataField),
                                                        onValueChanged: (e: InputEvent) =>
                                                            inputHandler(e, column.dataField),
                                                        format: column.format,
                                                        // dataType: column.dataType,
                                                        value:
                                                            column.dataType === 'number' && !data[column.dataField]
                                                                ? 0
                                                                : data[column.dataField],
                                                        dataSource:
                                                            column.lookup !== undefined &&
                                                            createStore({
                                                                key: 'id',
                                                                loadUrl: Api.createUrl(
                                                                    (column.lookup?.urlOverride ?? url) +
                                                                        column.lookup!.url +
                                                                        append?.get
                                                                ),
                                                                loadMode: 'raw',
                                                                onBeforeSend: (_operation, ajax) => {
                                                                    const headers: IDictionary<string> = {
                                                                        Authorization: `Bearer ${Api.token}`,
                                                                    };
                                                                    ajax.headers = headers;
                                                                },
                                                            }),
                                                        displayExpr: 'value',
                                                        valueExpr: 'id',
                                                        readOnly: !column.allowEditing,
                                                        autoResizeEnabled: column.editorType,
                                                    }}
                                                    validationRules={column.editorOptions?.validationRules}
                                                />
                                            );
                                        })}
                                    </Item>
                                );
                            })}

                        {layout.extras?.egenskaber && (
                            <Item key={'egenskaber'} colSpan={2}>
                                <AutoGridEgenskaber
                                    url={`${Api.createUrl(url)}/egenskaber/`}
                                    idRef={egeId}
                                    ref={egenskaberRef}
                                />
                            </Item>
                        )}
                        {/** Files and Images */}
                        {(layout.extras?.allowFiles || layout.extras?.allowImages) && (
                            <Item
                                itemType='group'
                                caption={
                                    layout.extras?.allowFiles
                                        ? layout.extras?.allowImages
                                            ? 'Filer og Billeder'
                                            : 'Billeder'
                                        : 'Filer'
                                }
                                colCount={1}
                                colSpan={2}
                                key='FileGroup'
                            >
                                {layout.extras?.allowImages && (
                                    <Item>
                                        <AutoGridImageGrid filelist={filelist} />
                                    </Item>
                                )}

                                {layout.extras?.allowFiles && (
                                    <Item>
                                        <AutoGridFileGrid filelist={filelist} />
                                    </Item>
                                )}
                            </Item>
                        )}
                    </Form>
                </Scrollable>
            </div>
        </div>
    );
};

export default PopupForm;
