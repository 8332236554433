import './auto-grid.css';

import { AutoGridContext, AutoGridProvider, IAutoGridContext } from './context';
import AutoGridEgenskaber, { IEgenskaberRef } from './egenskaber';
import { AutoGridFileGrid, AutoGridImageGrid } from './filelist';
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    EmailRule,
    Export,
    FilterRow,
    Form,
    GroupItem,
    GroupPanel,
    Grouping,
    HeaderFilter,
    Lookup,
    Paging,
    PatternRule,
    Popup,
    RequiredRule,
    Scrolling,
    SearchPanel,
    Sorting,
    Summary,
    ToolbarItem,
} from 'devextreme-react/data-grid';
import GetCellRender, { CellRenderKeys } from './render-cells';
import { IDictionary, PropsOf, Replace } from '../../shared/utils/types';
import { IItem, TItemValue } from '../auto-form/v1/interfaces-and-defaults';
import { ILayout, IRowData, RenderedSpecialColumn, ZLayout, zLayout } from './layout-interface';
import dxDataGrid, {
    DataChangeInfo,
    DataErrorOccurredInfo,
    EditingStartEvent,
    ExportingEvent,
    RowClickEvent,
    RowPreparedEvent,
} from 'devextreme/ui/data_grid';
import { memo, useContext, useMemo, useRef, useState } from 'react';
import { useCTEffect, useCTMemo } from '../../shared/hooks/use-ct';

import Api from '../../shared/networking/api';
import CustomStore from 'devextreme/data/custom_store';
import { Divider } from '@mui/material';
import { EventInfo } from 'devextreme/events';
import Exporting from './exporting';
import { IFile } from '../file-displaying/common';
import { InputEvent } from 'devextreme/ui/text_box';
import { Item } from 'devextreme-react/form';
import { StateStoring } from 'devextreme-react/pivot-grid';
import { ToolbarProvider } from './toolbar/context';
import UtilsString from '../../shared/utils/utils-string';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { flatmapSpecialColumns } from './map-foreach-functions';
import getToolbar from './toolbar';
import { isMobile } from 'react-device-detect';
import { sortObjectArray_Number } from '../../shared/utils/sorts';
import useColumnSlice from '../../shared/hooks/use-column-slice';
import { useEnhedId } from '../../shared/hooks/use-enhed-id';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export interface AutoGridProps {
    /** The base url for the api serving the grid */
    url: string;

    /** The layout file, indicating what the grid should look like. Follows the ILayout interface.
     * - Can either be a direct object, or a url to the endpoint at which the JSON can be fetched
     * - If not set: Defaults to `${EntityEndpoint}/layout`
     * */

    layoutJson: ILayout;

    /** An object with additional parameters, appended to the URL of different requests */
    append?: {
        get?: string;
        post?: string;
        put?: string;
        remove?: string;
    };

    /**
     * A function called whenever new data is fetched. Can be used to alter the data before it is passed to the grid.
     */
    onLoaded?: Parameters<typeof createStore>[0]['onLoaded'];

    /** Edit mode. @default 'popup'' */
    editMode?: 'batch' | 'cell' | 'row' | 'form' | 'popup';
    filter?: (string | (string | number)[])[];
    onRowClick?: (e: RowClickEvent<IRowData>) => void;
    noDataText?: string;
}

export type ValidatedAutoGridProps = Replace<AutoGridProps, 'layoutJson', ZLayout>;

interface AutoGrid_WithListsProps extends ValidatedAutoGridProps {
    id: string;
    setGridRef: IAutoGridContext['setGridRef'];
}

const _AutoGrid = ({
    id,
    url: _url,
    layoutJson: layout,
    append: { get = '', post: _post = '', put = '', remove = '' } = {},
    setGridRef,
    onLoaded,
    editMode = 'popup',
    filter,
    onRowClick,
    noDataText,
}: AutoGrid_WithListsProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const url = Api.createUrl(_url);
    const gridRef = useRef<DataGrid>(null);
    const dataRef = useRef<IRowData>({ id: -1 });
    const egenskaberRef = useRef<IEgenskaberRef>(null);
    const egeId = useRef<string>('');
    const filelist = useRef<IFile[]>([]);
    const enhId = useEnhedId();
    const { bygId, delId } = useParams<{ bygId: string; delId: string }>();
    const slicedColumns = useColumnSlice(
        [...layout.columns].sort(sortObjectArray_Number('formIndex')).filter((c) => c.visibleInForm !== false)
    );

    const dataSource = useCTMemo(
        () =>
            createStore({
                key: 'id',
                loadUrl: url + get,
                updateUrl: url + put,
                deleteUrl: url + remove,
                onBeforeSend: (_operation, ajax) => {
                    const headers: IDictionary<string> = {
                        Authorization: `Bearer ${Api.token}`,
                    };
                    ajax.headers = headers;
                },
                onLoaded: getOnLoaded(layout, onLoaded),
            }),
        [url, get, put, remove]
    );

    // args are unknown due to lack of typing in dxDataGrid columns
    // TODO: Confirm if this memo is really doing nothing. Layout could be a new object quite often
    const lookupParams: IDictionary<(options?: { data: IDictionary }, ...args: unknown[]) => { store: CustomStore }> =
        useCTMemo(() => {
            return layout.columns.reduce((lookups, column) => {
                if (column.lookup === undefined) return lookups;

                lookups[column.dataField] = (options?: { data: IDictionary }) => ({
                    store: createStore({
                        key: 'id',
                        loadUrl: (column.lookup?.urlOverride ?? url) + column.lookup!.url + get,
                        loadMode: 'raw',
                        onBeforeSend: (_operation, ajax) => {
                            ajax.data = options?.data;
                            const headers: IDictionary<string> = {
                                Authorization: `Bearer ${Api.token}`,
                            };
                            ajax.headers = headers;
                        },
                    }),
                });
                return lookups;
            }, {} as NonNullable<typeof lookupParams>);
        }, [layout]);

    // Should be rewritten with reduce
    const specialColumns: RenderedSpecialColumn[] | undefined = useMemo(
        () => layout.specialColumns?.flatMap((column, index) => flatmapSpecialColumns(column, index)),
        [layout]
    );

    const cellRenderArgs: IDictionary<unknown> = useMemo(
        () => ({
            // [CellRenderKeys.Default]: {},
            [CellRenderKeys.Drift_Ansvarlig_Over_Beskrivelse]: { ansvarligLookup: lookupParams?.['Ansvarlig']?.() },
            [CellRenderKeys.Drift_Interval_Type_Over_Beskrivelse]: {
                intervalTypeStore: lookupParams?.['Interval_Type']?.(),
            },
        }),
        [lookupParams]
    );

    const GetProppedCellRender = (key: string) => GetCellRender(key, cellRenderArgs[key]);

    //#region event handlers

    // Sæt toolbar og filelist og så rowToEdit
    const handleRowClick = async (e: RowClickEvent<IRowData>): Promise<void> => {
        if (e.rowType !== 'data') return;
        dataRef.current = e.data;
        if (onRowClick) onRowClick(e);
        if (layout.extras?.schemaOnRowClick) {
            (e.rowElement.lastChild?.firstChild?.firstChild as HTMLButtonElement).click();
            return;
        }
        if (layout.extras.allowEditing) e.component.editRow(e.rowIndex);
    };

    const handleEditingStart = (e: EditingStartEvent) => {
        filelist.current = e.data.files ?? e.data.filer ?? [];
        egeId.current = e.data.id ?? '';
    };

    // Snackbar på successfuldt gem
    const handleOnSaved = (e: EventInfo<dxDataGrid> & DataChangeInfo): void => {
        if (e.changes.length > 0) enqueueSnackbar('Ændringer er gemt!', { variant: 'success' });
        // else
        //     enqueueSnackbar("Ingen ændringer foretaget.", { variant: "info" })
    };

    // Gem billeder når ny entry oprettes
    const handleInitNewRow = () => {
        filelist.current = [];
        egeId.current = '';
    };

    // Sig hvis der sker en datafejl
    const handleDataErrorOccurred = (e: EventInfo<dxDataGrid> & DataErrorOccurredInfo) => {
        console.error(e.error);
        enqueueSnackbar('Undskyld. Der skete en fejl.', { variant: 'error' });
    };

    const handlePopupHidden = () => {
        gridRef.current?.instance.refresh(true);
    };

    const handleFormContentReady = (e: { component: unknown; element: HTMLDivElement }) => {
        const findAndHide = (element: HTMLElement) => {
            if (
                element.tagName === 'DIV' &&
                element.classList.contains('dx-item') &&
                element.classList.contains('dx-box-item')
            )
                return (element.style.display = 'none');
            element.parentElement && findAndHide(element.parentElement);
        };

        const itemsToHide = layout.columns.reduce((items: string[], column) => {
            if (column.visibleIfNoData === false && dataRef.current[column.dataField] == null)
                items.push(column.dataField);
            return items;
        }, []);
        itemsToHide.forEach((item) => {
            const element = e.element.querySelector(`input[class^="dx"][id$="${item}"]`);
            if (element != null) findAndHide(element as HTMLElement);
            return element;
        });
    };

    const onInput = async (e: InputEvent & { component: { _parsedValue: number } }) => {
        if (!e.event) return;
        const data = gridRef.current!.instance.getVisibleRows().filter((item) => item.isEditing === true)[0].data;
        if (String(e.event.currentTarget.id).endsWith('maengde')) {
            const priceResponse = await Api.get<string>(
                `/Byg/Aktivitet/Lookup/Enhedspris?priskatalog=${data.priskatalog}&mgd=${e.component._parsedValue}`
            );
            const price = priceResponse.data;
            console.log(price);
            data.enhedspris = price;
            data.nutidspris = e.component._parsedValue * parseFloat(price);
            const formData = new FormData();
            const values = `{ "enhedspris": ${price}, "nutidspris": ${parseInt(
                String(e.component._parsedValue * parseFloat(price))
            )} }`;
            formData.append('key', data.id);
            formData.append('values', values);

            const response = await Api.put(`/Byg/Aktivitet`, formData);
            if (!Api.ok(response)) enqueueSnackbar('Undskyld. Noget gik galt.', { variant: 'error' });
        } else if (String(e.event.currentTarget.id).endsWith('enhedspris')) {
            console.log(data.enhedspris);
            data.nutidspris = data.maengde * e.component._parsedValue;
            const formData = new FormData();
            const values = `{ "nutidspris": ${parseInt(String(data.maengde * e.component._parsedValue))} }`;
            formData.append('key', data.id);
            formData.append('values', values);

            const response = await Api.put(`/Byg/Aktivitet`, formData);
            if (!Api.ok(response)) enqueueSnackbar('Undskyld. Noget gik galt.', { variant: 'error' });
        } else return;
        gridRef.current?.instance._refresh();
    };

    const [exportingEvent, setExportingEvent] = useState<ExportingEvent | null>(null);
    const onExporting = (e: ExportingEvent) => {
        setExportingEvent(e);
    };

    const handleRowPrepared = (e: RowPreparedEvent) => {
        // Display columns without data with group key on top, instead of creating an empty group
        if (e.rowType === 'group' && UtilsString.IsNullOrWhitespace(e.values[e.groupIndex!])) {
            // Semantic children are not children in DOM, so we can simply hide the group column, while showing all children
            e.rowElement.style.display = 'none';
            if (!e.component.isRowExpanded(e.key)) e.component.expandRow(e.key);
        }
    };

    //#endregion event handlers

    const submitEgenskaber = async () => {
        const data = new FormData(egenskaberRef.current?.egenskaberRef.current?.element);
        const items: IItem[] = [];
        const prevData = egenskaberRef.current?.data ?? [];
        prevData.map((gruppe) => gruppe.items.map((item) => items.push(item)));
        const keys = items.map((item) => item.id);

        for (const dataKey of keys) {
            for (const [key, value] of data.entries()) {
                // if (value === '' && !keys.includes(key)) {
                //     egeData.delete(key);
                // }
                if (dataKey === key) {
                    if (items.find((item) => item.id === dataKey && (item.value ?? '') === value)) {
                        data.delete(key);
                    } else {
                        for (let i = 0; i < prevData.length; i++) {
                            for (let k = 0; k < prevData[i].items.length; k++) {
                                if (prevData[i].items[k].id === key) {
                                    prevData[i].items[k].value = value as TItemValue;
                                }
                            }
                        }
                    }
                }
            }
        }

        let length = 0;
        data.forEach((_value, _key) => {
            length += 1;
        });

        if (length > 0) {
            const response = await Api.post(egenskaberRef.current?.url + (egeId.current ?? ''), data);
            if (Api.ok(response)) {
                egenskaberRef.current?.refetch();
            }
        }
    };

    useCTEffect(() => {
        setGridRef(gridRef);
        filter && gridRef.current?.instance?.filter(filter);
    }, [setGridRef]);

    return (
        <ToolbarProvider>
            {/* Autogrid */}
            <DataGrid
                id={id}
                ref={gridRef}
                className='auto-grid'
                dataSource={dataSource}
                showBorders={true}
                showColumnLines={layout.extras.showColumnLines}
                hoverStateEnabled={true}
                onRowClick={handleRowClick}
                onRowPrepared={handleRowPrepared}
                onEditingStart={handleEditingStart}
                onDataErrorOccurred={handleDataErrorOccurred}
                onSaved={handleOnSaved}
                onInitNewRow={handleInitNewRow}
                onExporting={onExporting}
                width='100%'
                height='100%'
                loadPanel={{
                    text: 'Henter...',
                }}
                wordWrapEnabled
                showColumnHeaders={layout.extras.showColumnHeaders}
                repaintChangesOnly={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                rowAlternationEnabled
                noDataText={noDataText}
            >
                <Scrolling mode={layout.extras.scrollingMode} />
                <Paging enabled={false} />
                <Sorting mode='multiple' />
                <ColumnChooser enabled={layout.extras.showColumnChooser} mode='select' />
                <HeaderFilter visible={layout.extras.showHeaderFilter} />
                <FilterRow visible={layout.extras.showFilterRow} />
                <Export enabled={layout.extras.export} />
                <StateStoring
                    enabled={layout.extras.showColumnChooser}
                    type={'localStorage'}
                    storageKey={`layout${enhId ? enhId + '/' : ''}${bygId ? bygId + '/' : ''}${
                        delId ? delId + '/' : ''
                    }${layout.extras.context ?? ''}`}
                />
                <SearchPanel visible />
                <GroupPanel visible={layout.extras.showColumnHeaders} />
                <Grouping autoExpandAll={layout.extras.autoExpandGroups} expandMode='rowClick' />
                {getToolbar(layout)}
                <Summary>
                    <GroupItem column='Id' summaryType='count' />
                </Summary>
                <Editing
                    mode={editMode}
                    allowUpdating={layout.extras.allowEditing}
                    allowAdding={layout.extras.allowAdding}
                    allowDeleting={layout.extras.allowDeleting}
                    selectTextOnEditStart={false}
                    // onChangesChange={onInput}
                >
                    <Popup
                        title='Title'
                        showTitle={false}
                        fullScreen={isMobile}
                        showCloseButton={false}
                        hideOnOutsideClick={false}
                        onHidden={handlePopupHidden}
                        animation={{ undefined }}
                        // animation={{show: { type: 'slide', delay: 1000, }}}
                        // animation={{show: { type: 'slide', duration: 400, from: { position: { my: 'top', at: 'bottom', of: window } }, to: { position: { my: 'center', at: 'center', of: window } } }, hide: { type: 'slide', duration: 400, from: { position: { my: 'center', at: 'center', of: window } }, to: { position: { my: 'top', at: 'bottom', of: window } } }}}
                    >
                        {/* Popup toolbar */}
                        <ToolbarItem
                            key='ptbi1'
                            widget='dxButton'
                            toolbar='top'
                            location='center'
                            options={{
                                text: 'Gem',
                                type: 'success',
                                onClick: () => {
                                    gridRef.current?.instance
                                        ? gridRef.current?.instance?.saveEditData()
                                        : console.log('ref error', gridRef);
                                    submitEgenskaber();
                                },
                            }}
                        />
                        <ToolbarItem
                            key='ptbi2'
                            widget='dxButton'
                            toolbar='top'
                            location='center'
                            options={{
                                text: 'Annullér',
                                onClick: () => {
                                    gridRef.current?.instance
                                        ? gridRef.current?.instance?.cancelEditData()
                                        : console.log('ref error', gridRef);
                                },
                            }}
                        />
                    </Popup>
                    <Form labelLocation='top' alignItemLabels='left' onContentReady={handleFormContentReady}>
                        {}
                        {/* Form Items */}
                        <Item colSpan={2}>
                            <span style={{ fontSize: '19px' }}>Redigerer</span>
                            <Divider sx={{ pb: '5px', mb: '5px' }} />
                        </Item>
                        {layout.columns &&
                            slicedColumns.map((arr, i) => {
                                return (
                                    <Item itemType='group' colCount={12} colSpan={2} key={`fig1-group-${i}`}>
                                        {/* Form Columns */}
                                        {arr.map((column, index) => {
                                            // Special Editors
                                            switch (column.editorType) {
                                                case 'dxTextArea':
                                                    return (
                                                        <Item
                                                            colSpan={column.colSpan === 'max' ? 12 : column.colSpan}
                                                            key={`figi${index}`}
                                                            dataField={column.dataField}
                                                            editorType='dxTextArea'
                                                            editorOptions={{
                                                                autoResizeEnabled: true,
                                                                maxLength: column.maxLength,
                                                            }}
                                                            validationRules={column.editorOptions?.validationRules}
                                                        />
                                                    );
                                            }

                                            // Default
                                            return (
                                                <Item
                                                    colSpan={column.colSpan === 'max' ? 12 : column.colSpan}
                                                    label={{
                                                        visible: !column.hideCaption,
                                                    }}
                                                    dataField={column.dataField}
                                                    key={`figi${index}`}
                                                    editorOptions={{
                                                        maxLength: column.maxLength,
                                                        onInput,
                                                    }}
                                                    validationRules={column.editorOptions?.validationRules}
                                                />
                                            );
                                        })}
                                    </Item>
                                );
                            })}

                        {layout.extras.egenskaber && (
                            <Item key={'egenskaber'} colSpan={2}>
                                <AutoGridEgenskaber url={`${url}/egenskaber/`} idRef={egeId} ref={egenskaberRef} />
                            </Item>
                        )}
                        {/** Files and Images */}
                        {(layout.extras.allowFiles || layout.extras.allowImages) && (
                            <Item
                                itemType='group'
                                caption={
                                    layout.extras.allowFiles
                                        ? layout.extras.allowImages
                                            ? 'Filer og Billeder'
                                            : 'Billeder'
                                        : 'Filer'
                                }
                                colCount={1}
                                colSpan={2}
                                key='FileGroup'
                            >
                                {layout.extras.allowImages && (
                                    <Item>
                                        <AutoGridImageGrid filelist={filelist} />
                                    </Item>
                                )}

                                {layout.extras.allowFiles && (
                                    <Item>
                                        <AutoGridFileGrid filelist={filelist} />
                                    </Item>
                                )}
                            </Item>
                        )}
                    </Form>
                </Editing>

                {/* Special Columns - Before */}
                {specialColumns?.filter((c) => c.position === 'before').map((column) => column.element)}

                {/* Columns */}
                {layout.columns &&
                    [...layout.columns].sort(sortObjectArray_Number('gridIndex')).map((column) => {
                        const props: PropsOf<Column> = {
                            ...column,
                            cellRender: GetProppedCellRender(column.cellRenderKey),
                            cssClass: 'dx-auto-grid-vertical-center',
                            format: typeof column.format === 'string' ? column.format : undefined,
                            editorOptions: {
                                format: column.format,
                                searchEnabled: column.lookup ? false : undefined,
                                pickerType:
                                    column.dataType === 'date'
                                        ? !column.allowEditing
                                            ? 'calendar'
                                            : isMobile
                                            ? 'native'
                                            : 'calendar'
                                        : undefined,
                                ...column.editorOptions,
                            },
                        };

                        // Default
                        return (
                            <Column key={column.dataField} {...props}>
                                {/* Input Validering */}
                                {column.validationRules.requiredRule && <RequiredRule />}
                                {column.validationRules.emailRule && <EmailRule />}
                                {column.validationRules.patternRule && (
                                    <PatternRule
                                        message={column.validationRules.patternRule.message}
                                        pattern={((): RegExp => {
                                            // https://stackoverflow.com/a/874742 Dette er muligvis blind copy-paste der ikke fungerer?
                                            const match = column.validationRules.patternRule.pattern.match(
                                                new RegExp('^/(.*?)/([gimy]*)$')
                                            ) as RegExpMatchArray;
                                            return new RegExp(match[1], match[2]);
                                        })()}
                                    />
                                )}
                                {/* Lookup */}
                                {column.lookup && (
                                    <Lookup
                                        dataSource={lookupParams[column.dataField]}
                                        valueExpr='id'
                                        displayExpr='value'
                                        allowClearing
                                    />
                                )}
                            </Column>
                        );
                    })}

                {/* Special Columns - After */}
                {specialColumns?.filter((c) => c.position === 'after').map((column) => column.element)}
                {/* <Column type="buttons" visible={false} /> */}
            </DataGrid>
            {exportingEvent && <Exporting e={exportingEvent} setExportingEvent={setExportingEvent} />}
        </ToolbarProvider>
    );
};

const getOnLoaded = (layout: ZLayout, onLoaded: AutoGridProps['onLoaded']) => (data: Record<string, string>[]) => {
    // Arr to save keys which need altering
    const disabledDateKeys: string[] = [];

    // Find keys
    layout.columns.forEach((c) => {
        if (c.dataType === 'date' && c.allowEditing === false) disabledDateKeys.push(c.dataField);
    });

    // Alter data at keys
    data.forEach((d) => {
        disabledDateKeys.forEach((key) => d[key] && (d[key] = (d[key] as string).split('T')[0]));
    });

    // Prop onLoaded
    onLoaded?.(data);
};

const MemoedGrid = memo(
    _AutoGrid,
    (prevProps, nextProps) =>
        prevProps.append?.get === nextProps.append?.get &&
        prevProps.append?.post === nextProps.append?.post &&
        prevProps.append?.put === nextProps.append?.put &&
        prevProps.url === nextProps.url
);

const AutoGrid_WithContext = (props: ValidatedAutoGridProps) => {
    const context = useContext(AutoGridContext);

    return <MemoedGrid {...props} {...context} />;
};

/**
 * A datagrid automatically generated from a layout file, capable of displaying and editing data bound to the given api endpoint
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @returns An automatically generated datagrid, based on devextremes dxDataGrid
 */
const AutoGrid = (props: AutoGridProps) => {
    const validProps: ValidatedAutoGridProps = {
        ...props,
        layoutJson: zLayout.parse(props.layoutJson),
    };

    return (
        <AutoGridProvider props={validProps}>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <AutoGrid_WithContext {...validProps} />
        </AutoGridProvider>
    );
};

export default AutoGrid;
