import { LogLevel, Configuration } from "@azure/msal-browser";
import { MSAL_API_ID, MSAL_AUTHORITY, MSAL_CLIENT_ID, MSAL_REDIRECT_URI } from "./config";

export const msalConfig: Configuration = {
    auth: {
        clientId: MSAL_CLIENT_ID,
        authority: MSAL_AUTHORITY,
        redirectUri: MSAL_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            logLevel: LogLevel.Verbose,
            loggerCallback: (_level, message) => {
                console.log(message);
            },
        },
    },
};

export const loginRequest = {
    scopes: [`api://${MSAL_API_ID}/entraScope`],
};
