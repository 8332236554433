import { useContext, useEffect, useState } from 'react';
import { CommandIconButton, ICommandProps } from '../render-commands';
import { AutoGridContext } from '../context';
import usePopup from '../../../shared/hooks/use-popup';
import useFetch from '../../../shared/hooks/fetch-hooks/use-fetch';
import EditIcon from '@mui/icons-material/Edit';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { mængdePrisInput } from '../../data-displaying/timeline/new.timeline.variants/plan.timeline.strategies';
import { isMobile } from 'react-device-detect';
import PopupForm from '../popup-form';
import { ILayout } from '../layout-interface';

const CommandEditAktivitetFromIdOnly = (props: ICommandProps) => {
    const [driftLayout, setDriftLayout] = useState<ILayout | null>(null);

    const { gridRef, props: gridProps } = useContext(AutoGridContext);
    const [Popup, show, hide, visible] = usePopup(false);

    console.log(props.dxProps.data);
    const aktid = props.dxProps.data.aktid;

    const [data, isLoading, , , , refetch] = useFetch<any>(`/Drift/Aktivitet?aktivitetssys=${aktid}`, {
        autoFetch: false,
    });

    const disabled = props.disabled;

    const onClick: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        if (disabled) return;
        refetch();
        show();
    };

    const close = () => {
        hide();
        props.closeParent?.();
    };

    useEffect(() => {
        import('../layout-drift').then((module) => setDriftLayout(module.default));
    }, []);

    const iconButton = (
        <CommandIconButton
            key={`command-edit-akt-${props.dxProps.key}`}
            onClick={onClick}
            //component="span"
            className='auto-grid-icon-button'
            disabled={disabled}
            style={{ display: props.hidden ? 'none' : undefined }}
        >
            <EditIcon key={`command-edit-aci1-${props.dxProps.key}`} />
        </CommandIconButton>
    );

    const menuItem = (
        <MenuItem disabled={disabled} key={`command-edit-akt-${props.dxProps.key}`} onClick={onClick}>
            <ListItemIcon key={`command-edit-lii-${props.dxProps.key}`}>
                <EditIcon key={`command-edit-aci2-${props.dxProps.key}`} color={disabled ? undefined : 'action'} />
            </ListItemIcon>
            <Typography>Rediger</Typography>
        </MenuItem>
    );

    const getPopupFormProps = () => {
        return {
            data: data[0],
            hide: close,
            layout: driftLayout,
            url: props.urlOverride ?? gridProps.url,
            afterSave: () => gridRef.current?.instance.refresh(),
            append: gridProps.append,
            onInput: gridProps.url.toLowerCase().includes('aktivitet') ? undefined : mængdePrisInput,
        };
    };

    return (
        <>
            {props.asMenuItem ? menuItem : iconButton}
            <Popup
                title='Title'
                showTitle={false}
                fullScreen={isMobile}
                showCloseButton={false}
                hideOnOutsideClick={false}
                onHidden={close}
                animation={undefined}
            >
                {/* Unmount content when not visible to avoid lookups fwtching data for every row in the grid */}
                {visible && !isLoading && <PopupForm {...getPopupFormProps()} />}
            </Popup>
        </>
    );
};

export default CommandEditAktivitetFromIdOnly;
